import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7e0aad46 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _4b33f9fa = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _03fbc118 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _0e7bfb72 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7cf39f9a = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _538d6942 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _8511308c = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _4df72ec4 = () => interopDefault(import('../pages/wef425g2/index.vue' /* webpackChunkName: "pages/wef425g2/index" */))
const _7c395774 = () => interopDefault(import('../pages/donate/success.vue' /* webpackChunkName: "pages/donate/success" */))
const _1c5235a2 = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _b08dd850 = () => interopDefault(import('../pages/wef425g2/comments.vue' /* webpackChunkName: "pages/wef425g2/comments" */))
const _7e01b816 = () => interopDefault(import('../pages/wef425g2/desc.vue' /* webpackChunkName: "pages/wef425g2/desc" */))
const _4da3add3 = () => interopDefault(import('../pages/wef425g2/donate.vue' /* webpackChunkName: "pages/wef425g2/donate" */))
const _1063f0e9 = () => interopDefault(import('../pages/wef425g2/feedback.vue' /* webpackChunkName: "pages/wef425g2/feedback" */))
const _63e12a03 = () => interopDefault(import('../pages/wef425g2/kino.vue' /* webpackChunkName: "pages/wef425g2/kino" */))
const _53903051 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bdc9a092 = () => interopDefault(import('../pages/comics/_id/index.vue' /* webpackChunkName: "pages/comics/_id/index" */))
const _39fd3d67 = () => interopDefault(import('../pages/comics/_id/chapter/_ch.vue' /* webpackChunkName: "pages/comics/_id/chapter/_ch" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/copyright",
    component: _7e0aad46,
    name: "copyright"
  }, {
    path: "/donate",
    component: _4b33f9fa,
    name: "donate"
  }, {
    path: "/popular",
    component: _03fbc118,
    name: "popular"
  }, {
    path: "/privacy",
    component: _0e7bfb72,
    name: "privacy"
  }, {
    path: "/signin",
    component: _7cf39f9a,
    name: "signin"
  }, {
    path: "/success",
    component: _538d6942,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _8511308c,
    name: "termsofuse"
  }, {
    path: "/wef425g2",
    component: _4df72ec4,
    name: "wef425g2"
  }, {
    path: "/donate/success",
    component: _7c395774,
    name: "donate-success"
  }, {
    path: "/profile/favs",
    component: _1c5235a2,
    name: "profile-favs"
  }, {
    path: "/wef425g2/comments",
    component: _b08dd850,
    name: "wef425g2-comments"
  }, {
    path: "/wef425g2/desc",
    component: _7e01b816,
    name: "wef425g2-desc"
  }, {
    path: "/wef425g2/donate",
    component: _4da3add3,
    name: "wef425g2-donate"
  }, {
    path: "/wef425g2/feedback",
    component: _1063f0e9,
    name: "wef425g2-feedback"
  }, {
    path: "/wef425g2/kino",
    component: _63e12a03,
    name: "wef425g2-kino"
  }, {
    path: "/",
    component: _53903051,
    name: "index"
  }, {
    path: "/comics/:id",
    component: _bdc9a092,
    name: "comics-id"
  }, {
    path: "/comics/:id?/chapter/:ch?",
    component: _39fd3d67,
    name: "comics-id-chapter-ch"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
