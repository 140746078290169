<template>
  <div class="search">
    <input
      id="search"
      ref="ssearch"
      type="search"
      name="search"
      v-model="searchQuery"
      :class="{ active: searchQuery }"
      @input="trottled"
      @focus="focused = true"
      @blur="focused = false"
      autocomplete="off"
      placeholder="Начните вводить название"
    >
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>Ничего не найдено</span>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="(item, i) in searchHints"
          :to="`/comics/${postUrl(item.id, item.title)}`"
          :key="`sh-${i}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" alt="">
          </div>
          <p>
            <strong>{{ item.title }}</strong>
            <span v-if="item.category">{{ item.category }}</span>
            <template v-if="item.category && item.year">&middot;</template>
            <span v-if="item.year">{{ item.year }}</span>
            <template v-if="item.genres.length && item.year">&middot;</template>
            <span v-if="item.genres.length">{{ item.genres.join(', ') }}</span>
          </p>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.searchHints = data
      }
    }
  }
}
</script>

<style lang="stylus">
.search
  position: relative
  input::placeholder
    color: rgba(255, 255, 255, 0.35)
  .datalist
    position: absolute
    padding-bottom: 10px
    width: 100%
    color #fff
    background-color #2d2d2d
    border-radius: 0px 0px 4px4px
    box-shadow 0 0 10px 0px rgba(0, 0, 0, 0.2)
    z-index 4
    .no-match
      padding 20px
      a
        color #000
    ul
      list-style none
      padding 10px 0px
      max-height 400px
      overflow auto
      -webkit-overflow-scrolling touch
      &::-webkit-scrollbar-track
        width: 10px
        background rgba(0, 0, 0, 0)
      &::-webkit-scrollbar
        width: 10px
      &::-webkit-scrollbar-thumb
        background rgba(0, 0, 0, 0)
      &:hover::-webkit-scrollbar-thumb
        background #ddd
      li
        display grid
        grid-template-columns 50px auto
        grid-gap 0px 10px
        align-items start
        padding 7px 15px
        cursor pointer
        .poster
          position relative
          width 50px
          height 80px
          background-color: #f1f1f1
          border-radius: 4px
          overflow hidden
          img
            width 100%
            min-height 100%
            object-fit cover
        p
          width calc(100% - 65px)
          overflow hidden
          strong
            display block
            width 100%
            font-size 1.4rem
            border none
          span
            font-size 1.2rem
            text-transform lowercase
            opacity 0.75
      li:hover
        background: rgba(0, 0, 0, 0.1)
</style>
