<template>
  <div class="updates">
    <!-- <div class="h2">Обновлены недавно</div> -->
    <div class="btns" style="padding: 0px 10px;">
      <button @click="itemsType = 'popular'" :class="{ active: itemsType === 'popular' }">Популярно</button>
      <button @click="itemsType = 'updates'" :class="{ active: itemsType === 'updates' }">Обновлены недавно</button>
      <a href="https://1xanime.ru"><button>Аниме</button></a>
      <a href="https://1xkino.ru"><button>Кино</button></a>
    </div>
    <div class="slider-wrapper">
      <div :style="`width:${items[itemsType].length * 180}px;`" class="slider">
        <nuxt-link v-for="item in items[itemsType]" :key="`slider-item-${itemsType}-${item.id}`" :to="`/comics/${postUrl(item.id, item.title)}`" tag="div" class="item">
          <div class="poster">
            <img :data-src="item.poster" :alt="item.title" src="/static_files/no-poster.png" class="lazyload">
            <!-- <img :data-src="item.poster" :alt="item.title" src="/static_files/no-poster.png" class="lazy"> -->
          </div>
          <nuxt-link :to="`/comics/${postUrl(item.id, item.title)}`">{{ item.title }}</nuxt-link>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: {
        updates: [],
        popular: [],
      },
      itemsType: 'popular',
    }
  },
  async fetch() {
    const { data } = await this.$axios.get(`/api/updates`)

    if (!data.error) {
      this.items = data
    }
  },
}
</script>

<style lang="stylus">
.updates
  padding-top: 20px // 80px
  height: 365px
  .h2
    margin-bottom: 10px
    padding: 0px 10px
    font-size: 2rem
    font-weight: 800
  .slider-wrapper
    margin-top: 20px
  .btns
    button.active
      background-color: rgba(207, 161, 0, 0.9)
</style>