<template>
  <div v-cloak id="sitebody" :class="{ 'mobile': !isDesktop, 'premium': user && user.group !== 1 }">
    <Mobile v-if="!isDesktop" />

    <div v-if="isDesktop" v-show="showVideoAd" class="videoroll">
      <div class="close">
        <span v-if="videoAdCounter">Можно закрыть через {{ videoAdCounter }}</span>
        <i v-else @click="showVideoAd = !showVideoAd" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-6457457-3"></div>
      </div>
    </div>
    
    <template v-if="isDesktop">
      <transition name="toBottom">
        <div v-if="showMenu" class="mainmenu">
          <div style="text-align:left;">
            <nuxt-link to="/" class="logotype">
              <span class="f">1x</span>
              <span class="s">manga</span>
            </nuxt-link>
          </div>
          <Search />
          <div class="profile">
            <!-- <button @click="toggleTheme">Темная тема</button> -->
            <span v-if="user" @click="getFavs">Мои списки</span>
            <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">Мои списки</nuxt-link>
            <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
            <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
          </div>
        </div>
      </transition>
    </template>
    <template v-else>
      <div class="mainmenu">
        <transition name="toBottom">
          <Search v-if="showMenu" />
        </transition>
        <transition name="toTop">
          <div v-if="showMenu" class="profile">
            <div style="text-align:left;">
              <nuxt-link to="/" class="logotype">
                <span class="f">1x</span>
                <span class="s">manga</span>
              </nuxt-link>
            </div>
            <!-- <div style="text-align:center;">
              <span v-if="user" @click="getFavs">
                <img src="/static_files/icons/favourite-active.svg" alt="">
              </span>
              <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">
                <img src="/static_files/icons/favourite-active.svg" alt="">
              </nuxt-link> 
            </div>-->
            <div style="text-align:right;">
              <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
              <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
            </div>
          </div>
        </transition>
      </div>
    </template>

    <div v-if="showUserMenu" class="user-menu">
      <div @click="showUserMenu = false" class="close"></div>
      <div class="poster">
        <img :src="user.photo" alt="">
      </div>
      <strong>{{ user.email }}</strong>
      <div>
        Баланс: {{ user.balance }}
        <nuxt-link to="/donate">+</nuxt-link>
      </div>
      <div class="prem">
        Premium
        <template v-if="user.group === 777">
          до {{ user.premium_date | filterPremDate }}
          <div>
            <nuxt-link to="/donate">Продлить</nuxt-link>
          </div>
        </template>
        <template v-else>
          не активирован. <nuxt-link to="/donate">Подключить</nuxt-link>
        </template>
      </div>
      <div style="margin-bottom: 15px;">
        <span @click="getFavs" style="cursor: pointer;">
          Закладки
        </span>
      </div>
      <button @click="logout">Выйти</button>
    </div>

    <transition name="toLeft">
      <div v-if="userFavsD" class="user-favs">
        <i @click="userFavsD = null" class="close" />

        <div class="menu">
          <div :style="`width:${7 * 120}px;`">
            <div @click="favCurrent = 'like'" :class="{ active: favCurrent === 'like' }" class="item">Понравились</div>
            <div @click="favCurrent = 'planned'" :class="{ active: favCurrent ===  'planned' }" class="item">Запланировано</div>
            <div @click="favCurrent = 'watching'" :class="{ active: favCurrent ===  'watching' }" class="item">Читаю</div>
            <div @click="favCurrent = 'completed'" :class="{ active: favCurrent ===  'completed' }" class="item">Прочитано</div>
            <div @click="favCurrent = 'hold'" :class="{ active: favCurrent ===  'hold' }" class="item">Отложено</div>
            <div @click="favCurrent = 'dropped'" :class="{ active: favCurrent ===  'dropped' }" class="item">Брошено</div>
            <div @click="favCurrent = 'rewatching'" :class="{ active: favCurrent ===  'rewatching' }" class="item">Перечитываю</div>
          </div>
        </div>
        <div v-if="userFavs.length" class="body">
          <div v-for="item in userFavs" :key="`fav-${item._id}`" class="item">
            <div class="poster">
              <img :src="item.poster" alt="">
            </div>
            <template v-if="item.type">
              <div style="display:inline-block;">
                <strong>{{ item.title_ru }}</strong>
                <span v-if="item.part_id">Глава {{ item.part }}</span>
                <nuxt-link v-if="item.part_id" :to="`/comics/${item.id}/chapter/${item.part_id}`"></nuxt-link>
                <nuxt-link v-else :to="`/comics/${item.id}/chapter/0`"></nuxt-link>
              </div>
            </template>
            <template v-else>
              <div style="display:inline-block;">
                <strong>{{ item.title }}</strong>
                <nuxt-link :to="`/comics/${postUrl(item.id, item.title)}`"></nuxt-link>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="body">
          Ничего не найдено
        </div>
      </div>
    </transition>

    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-6457457-4" class="rsya-block"></div>
      <!-- <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link> -->
    </template>

    <Updates />

    <nuxt />

    <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-6457457-4-2" class="rsya-block"></div>
      <!-- <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link> -->
    </template>

    <footer class="footer">
      <div class="links">
        <a href="mailto:support@1xgroup.ru" target="_blank">
          support@1xgroup.ru
        </a>
        <a href="#" target="_blank">
          ВКонтакте
        </a>
        <a href="#" target="_blank">
          Телеграм
        </a>
      </div>
    </footer>

    <transition name="toBottom">
      <Error v-if="error" :error="error" />
    </transition>

    <FeedbackComponent v-if="feedback" />

    <i onclick="window.scrollTo(0,0)" class="scrollup" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    Search: () => import('~/components/Search.vue'),
    Error: () => import('~/components/Error.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    FeedbackComponent: () => import('~/components/FeedbackComponent.vue'),
    Updates: () => import('~/components/Updates.vue'),
  },
  filters: {
    filterPremDate(val) {
      const d = new Date(val)
      const day = d.getDate()
      const month = d.getMonth() + 1
      const year = d.getFullYear()
      const hour = d.getHours()
      const min = d.getMinutes()

      return `${day}.${month}.${year} ${hour}:${min}`
    },
  },
  watch: {
    favCurrent(val) {
      if (!this.userFavsD) return
      if (val !== 'like') {
        this.userFavs = this.userFavsD.favs.filter(el => el.action === val)
      } else {
        this.userFavs = this.userFavsD.likes
      }
    },
    userFavsD(val) {
      if (!val) {
        this.favCurrent = 'like'
        this.userFavs = []
      }
    },
    changeRoute() {

      this.userFavsD = null
      this.userFavs = []

      document.body.style.overflow = ''

      setTimeout(() => {
        this.lazyLoadImgs()
      }, 400)
    }
  },
  computed: {
    ...mapState(['error', 'feedback', 'isDesktop', 'user']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      favCurrent: 'like',
      userFavs: [],
      userFavsD: null,
      showUserMenu: false,
      currentFav: null,
      showVideoAd: false,
      videoAdCounter: 15,
      videoAdInterval: null,
      showMenu: true,
    }
  },
  mounted() {
    // this.lazyLoadImgs()

    // window.addEventListener('scroll', this.lazyLoadImgs)

    window.addEventListener('scroll', this.scrollTop)

    window.addEventListener('keyup', this.switchFavs)

    this.showAds()

    let lastPosition = 0

    window.addEventListener('scroll', () => {
      const currentPosition = document.documentElement.scrollTop

      if (currentPosition < (this.isDesktop ? 300 : 300)) {
        // if (!this.toggleMenu) this.showMenuBar = true
        this.showMenu = true
      } else if (currentPosition > lastPosition) {
        this.showMenu = false
      } else {
        this.showMenu = true
      }
      lastPosition = currentPosition
    })
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.switchFavs)
  },
  methods: {
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        this.videoAdInterval = setInterval(() => {
          if (this.videoAdCounter > 0) {
            --this.videoAdCounter
          }
        }, 1000)

        this.showVideoAd = true
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-4',
      blockId: 'R-A-6457457-4'
    })
    })`
          document.body.appendChild(rsyaHorizon)

          const rsyaHorizon2 = document.createElement('script')
          rsyaHorizon2.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-4-2',
      blockId: 'R-A-6457457-4',
      pageNumber: 2,
    })
    })`
          document.body.appendChild(rsyaHorizon2)

          if (!this.isDesktop) {
            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'floorAd',
      blockId: 'R-A-6457457-2'
    })
    })`
            document.body.appendChild(rsyaFooterMobile)
            
            const rsyaFullscreen = document.createElement('script')
            rsyaFullscreen.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'fullscreen',
      blockId: 'R-A-6457457-1'
    })
    })`
            // document.body.appendChild(rsyaFullscreen)
          } else {
            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-6457457-3',
      blockId: 'R-A-6457457-3'
    })
    })`
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
    switchFavs(event) {
      if (event.keyCode === 27) {
        this.userFavsD = null
        this.userFavs = []
      }
    },
    async getFavs() {
      const { data } = await this.$axios.get(`/api/user/favs`)
      // console.log('data', data)
      this.userFavsD = data
      this.userFavs = data.likes
    },
    scrollTop () {
      if (this.$route.name === 'comics-id-chapter-ch') return
      const elem = document.querySelector('.scrollup')
      if (document.documentElement.scrollTop > 1000) {
        elem.classList.add('sticky')
      } else {
        elem.classList.remove('sticky')
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
  }
}
</script>
