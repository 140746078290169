<template>
  <div class="lastcomments">
    <div
      :style="`grid-template-columns:repeat(${comments.length}, 240px);width:calc(${comments.length * 260})px;`"
      class="wrapper"
    >
      <div v-for="item in comments" :key="`lcomm-${item._id}`" class="item">
        <div class="header">
          <div class="poster">
            <img :src="item.user_avatar" :alt="item.user_name">
          </div>
          <div>
            <strong>{{ item.user_name }}</strong>
            <span>&rarr;</span>
            <nuxt-link :to="`/comics/${postUrl(item.comics.id, item.comics.title)}`">{{ item.comics.title }}</nuxt-link>
          </div>
        </div>
        <p>{{ item.text.length > 200 ? item.text.slice(0, 200) + '...' : item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comments: { type: Array, require: true, default: () => []}
  },
  mounted() {
    this.parseBB()
  },
  methods: {
    parseBB() {
      const list = document.querySelectorAll('.lastcomments p')
      if (list) {
        Array.from(list).forEach((el) => {
          if (el.textContent.match(/\[:.*:\]/)) {
            const str = el.textContent
              .replace(/\[::i::\]/g, '<i>')
              .replace(/\[::\/i::\]/g, '</i>')
              .replace(/\[::b::\]/g, '<b>')
              .replace(/\[::\/b::\]/g, '</b>')
              .replace(/\[::color="/g, `<span style="color:`)
              .replace(/"::\]/g, `">`)
              .replace(/\[::\/color::\]/g, '</span>')
              .replace(/\[::/g, '<img src="/static_files/onion/')
              .replace(/::\]/g, '.gif" class="emoji" />')
            el.innerHTML = str
          }
        })
      }
    },
  }
}
</script>

<style lang="stylus">
.lastcomments
  margin-top: 20px
  width: 100%
  .wrapper
    padding: 10px
    display: grid
    grid-gap: 10px
    overflow: auto
    &::-webkit-scrollbar-track
      height: 10px
      background: rgba(0, 0, 0, 0)
    &::-webkit-scrollbar
      height: 10px
    &::-webkit-scrollbar-thumb
      background: rgba(0, 0, 0, 0)
    &:hover::-webkit-scrollbar-thumb
      background: #ddd
    .item
      display: inline-block
      padding: 10px
      width: 240px
      border: 2px solid #333
      border-radius: 10px
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1)
      p
        margin-top: 10px
        img
          width: auto
          height: 32px
      .header
        display: grid
        grid-template-columns: 28px auto
        grid-gap: 10px
        font-size: 1.2rem
        .poster
          width: 28px
          height 28px
          border-radius: 4px
          overflow: hidden
          img
            width: 100%
            min-height: 100%
            object-fit: cover
</style>