<template>
  <main class="index">
    <h1 style="text-align: center;">
      Читайте лучшие комиксы {{type ? type.toLowerCase() : 'манга'}}{{
        genre ? '. Жанр ' + genre : ''
      }}{{
        year ? '. Год ' + year + ')' : ''
      }}{{
        author ? '. Автор ' + author : ''
      }}{{
        translation ? '. Переводчик ' + translation : ''
      }}
    </h1>

    <LastComments :comments="comments" />

    <div class="content">
      <div v-if="!$device.isDesktop" class="filters">
        <div class="row">
          <strong @click="showSub($event)">
            {{ type ? type : 'Весь каталог'}}
          </strong>
          <div class="sub">
            <div @click="type = ''">Все</div>
            <div @click="type = 'Манга'">Манга</div>
            <div @click="type = 'Манхва'">Манхва</div>
            <div @click="type = 'Маньхуа'">Маньхуа</div>
            <div @click="type = 'Веб-манхва'">Веб-манхва</div>
          </div>
        </div>
        <div class="row">
          <strong @click="showSub($event)">
            {{ genre ? genre : 'Все жанры'}}
          </strong>
          <div class="sub">
            <div @click="genre = ''">Все</div>
            <div v-for="g in genres" :key="`filters-genre-${g}`" @click="genre = g">
              {{ g }}
            </div>
          </div>
        </div>
      </div>
      <div class="comics">
        <Items :items="items" />
        <div class="more" />

        <div class="description">
          <p>
            Манга – это японские комиксы, которые пользуются огромной популярностью во всем мире. Они отличаются от обычных комиксов своим стилем рисования, сюжетом и глубокими персонажами. Многие люди любят читать мангу, но не всегда есть возможность купить ее в магазине или на сайте. Однако, существует множество сайтов, где можно бесплатно читать мангу на русском языке.
          </p>
          <p>
            Один из таких сайтов – это 1xmanga. Здесь вы найдете огромную коллекцию манги на русском языке, которую можно читать бесплатно. Сайт постоянно обновляется новыми главами и сериями, так что вы всегда будете в курсе последних событий в мире манги.
            Сайт имеет удобный интерфейс и поиск, который поможет вам быстро найти нужную мангу.
          </p>
          <p>
            Если вы любите читать мангу на своем мобильном устройстве, то вам стоит обратить внимание на наше приложение. Оно имеет огромную коллекцию манги на русском языке, а также на других языках. Приложение имеет удобный интерфейс и поиск, который поможет вам быстро найти нужную мангу.
          </p>
          <p>
            В заключение, читать мангу бесплатно на русском языке можно на множестве сайтов и приложений. Они имеют огромную коллекцию манги, которая постоянно обновляется новыми главами и сериями. Так что, если вы любите мангу, но не хотите тратить деньги на ее покупку, то обязательно оставайтесь на нашем сайте и скачивайте наше мобильное приложение.
          </p>
        </div>
      </div>
      <div v-if="$device.isDesktop" class="filters">
        <div class="row">
          <strong @click="showSub($event)">
            {{ type ? type : 'Весь каталог'}}
          </strong>
          <div class="sub">
            <div @click="type = ''">Все</div>
            <div @click="type = 'Манга'">Манга</div>
            <div @click="type = 'Манхва'">Манхва</div>
            <div @click="type = 'Маньхуа'">Маньхуа</div>
            <div @click="type = 'Веб-манхва'">Веб-манхва</div>
          </div>
        </div>
        <div class="row">
          <strong @click="showSub($event)">
            {{ genre ? genre : 'Все жанры'}}
          </strong>
          <div class="sub">
            <div @click="genre = ''">Все</div>
            <div v-for="g in genres" :key="`filters-genre-${g}`" @click="genre = g">
              {{ g }}
            </div>
          </div>
        </div>

        <div v-if="$device.isDesktop" id="yandex_rtb_R-A-6457457-5" class="rsya-vertical"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {
    Items: () => import('~/components/Items.vue'),
    LastComments: () => import('~/components/LastComments.vue'),
  },
  head() {
    const title = `Читать лучшую ${this.type ? this.type.toLowerCase() : 'манга'} онлайн бесплатно на русском${
      this.genre ? '. Жанр ' + this.genre : ''
    }${
      this.year ? '. Год ' + this.year + ')' : ''
    }${
      this.author ? '. Автор ' + this.author : ''
    }${
      this.translation ? '. Переводчик ' + this.translation : ''
    }`

    const description = `На нашем сайте вы можете бесплатно читать онлайн комиксы ${this.type ? this.type.toLowerCase() : 'манга'} на русском и с хорошим качеством изображения${
      this.genre ? '. Жанр ' + this.genre : ''
    }${
      this.year ? '. Год ' + this.year + ')' : ''
    }${
      this.author ? '. Автор ' + this.author : ''
    }${
      this.translation ? '. Переводчик ' + this.translation : ''
    }`

    const fullPath = `https://1xmanga.ru${
      this.genre || this.type || this.year || this.author || this.translation ? '?' : ''
    }${
      this.type ? `type=${encodeURI(this.type)}` : ''
    }${
      this.genre ? `genre=${encodeURI(this.genre)}` : ''
    }${
      this.year ? `year=${encodeURI(this.year)}` : ''
    }${
      this.author ? `author=${encodeURI(this.author)}` : ''
    }${
      this.translation ? `translation=${encodeURI(this.translation)}` : ''
    }`

    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${this.type ? this.type : 'манга'} читать бесплатно на русском`
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        },
      ]
    }
  },
  watch: {
    type() {
      this.getData()
    },
    genre() {
      this.getData()
    }
  },
  watchQuery: ['type', 'genre', 'year', 'author', 'translation'],
  async asyncData({ $axios, query, error, redirect }) {
    const qArr = ['type', 'genre', 'year', 'author', 'translation']

    if (Object.keys(query).length) {
      if (
        !Object.keys(query).filter(key => {
          return qArr.includes(key) && query[key]
        }).length
      ) {
        return redirect('/') // error({ statusCode: 404, message: 'Not found' })
      }
    }

    const type = query.type ? query.type : ''
    const genre = query.genre ? query.genre : ''
    const year = query.year ? query.year : ''
    const author  = query.author  ? query.author  : ''
    const translation = query.translation ? query.translation : ''
    let url = `/api/index?page=0`
    if (type) url += `&type=${encodeURIComponent(type)}`
    if (genre) url += `&genre=${encodeURIComponent(genre)}`
    if (year) url += `&year=${encodeURIComponent(year)}`
    if (author) url += `&author=${encodeURIComponent(author)}`
    if (translation) url += `&translation=${encodeURIComponent(translation)}`

    const { data } = await $axios.get(url)
    
    return {
      ...data,
      page: 0,
      type,
      genre,
      year,
      author,
      translation,
      genres: [
        'боевые искусства', 'боевик', 'вампиры', 'гарем', 'гендерная интрига', 'героическое фэнтези', 'додзинси',
        'дзёсэй', 'драма', 'детектив', 'игра', 'история', 'киберпанк', 'комедия', 'мистика',
        'меха', 'махо-сёдзё', 'научная фантастика', 'перерождение', 'повседневность', 'приключения',
        'психология', 'романтика', 'самурайский боевик', 'сборник', 'сверхъестественное', 'сёдзё',
        'сёдзё для взрослых', 'сёдзё-ай', 'сёнэн', 'сёнэн-ай', 'спокон', 'сэйнэн', 'спорт', 'трагедия',
        'триллер', 'ужасы', 'фантастика', 'фэнтези', 'школа', 'эротика', 'этти', 'юри', 'яой'
      ],
    }
  },
  mounted() {
    if (this.$device.isDesktop) window.addEventListener('scroll', this.stickyFilters)
    window.addEventListener('scroll', this.showMore)

    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.addEventListener('scroll', this.lazyLoadImgs)

    if (this.$device.isDesktop) {
      setTimeout(() => {
        const rsyaVertical = document.createElement('script')
        rsyaVertical.innerHTML = `
  window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-6457457-5',
    blockId: 'R-A-6457457-5'
  })
  })`
        document.body.appendChild(rsyaVertical)
      }, 200)
    }
  },
  beforeDestroy() {
    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.removeEventListener('scroll', this.lazyLoadImgs)

    if (this.$device.isDesktop) window.removeEventListener('scroll', this.stickyFilters)
    window.removeEventListener('scroll', this.showMore)
  },
  methods: {
    getData() {
      // if (this.loading) return
      // this.loading = true
      const type = this.type ? this.type : ''
      const genre = this.genre ? this.genre : ''
      const year = this.year ? this.year : ''
      const author  = this.author ? this.author  : ''
      const translation = this.translation ? this.translation : ''
      let url = `/?page=0` // `/api/comics?page=0`
      if (type) url += `&type=${encodeURIComponent(type)}`
      if (genre) url += `&genre=${encodeURIComponent(genre)}`
      if (year) url += `&year=${encodeURIComponent(year)}`
      if (author) url += `&author=${encodeURIComponent(author)}`
      if (translation) url += `&translation=${encodeURIComponent(translation)}`
      this.$router.push(url)
      // const { data } = await this.$axios.get(url)
      // this.items = data.items
      // this.loading = false
      // window.scrollTo(0, 0)
      // this.$nextTick(() => {
      //   this.lazyLoadImgs()
      // })
    },
    async showMore() {
      if (this.loading) return
      const elem = document.querySelector('.more')
      if (!elem) return
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        this.loading = true
        const type = this.type ? this.type : ''
        const genre = this.genre ? this.genre : ''
        const year = this.year ? this.year : ''
        const author  = this.author  ? this.author  : ''
        const translation = this.translation ? this.translation : ''
        let url = `/api/comics?page=${++this.page}`
        if (type) url += `&type=${encodeURIComponent(type)}`
        if (genre) url += `&genre=${encodeURIComponent(genre)}`
        if (year) url += `&year=${encodeURIComponent(year)}`
        if (author) url += `&author=${encodeURIComponent(author)}`
        if (translation) url += `&translation=${encodeURIComponent(translation)}`
        const { data } = await this.$axios.get(url)
        const items = this.items.concat(data.items)
        this.items = items
        this.loading = false
      }
    },
    stickyFilters() {
      const elem = document.querySelector('.filters')
      if (!elem) return

      const leftPos = elem.getBoundingClientRect().left

      if (window.scrollY > 1000) {
        elem.style.position = 'fixed'
        elem.style.top = '70px'
        elem.style.left = leftPos + 'px'
      } else {
        elem.style.position = 'relative'
        elem.style.top = '0px'
        elem.style.left = '0px'
      }
    },
    showSub(e) {
      const el = e.target.nextElementSibling
      if (el.style.display === 'block') {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    }
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
